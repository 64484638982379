import { IAppwriteDocument } from './appwriteDocument';

export interface ILicenseRecord {
  label: string;
  date: number | null;
  licenseClasses: string;
  clientId: string;
}

export interface ILicenseRecordInstance
  extends ILicenseRecord,
    IAppwriteDocument {}

export const isILicenseRecordInstance = (
  record: ILicenseRecord | ILicenseRecordInstance,
): record is ILicenseRecordInstance =>
  Object.prototype.hasOwnProperty.call(record, '$id');

export interface IOffenseRecord {
  date: number | null;
  offense: string;
  offenseTime?: number;
  bloodCollectionTime?: number;
  breathAlcoholConcentration: string;
  bloodAlcoholConcentration: string;
  blockingPeriod: number | null;
  clientId: string;
}

export interface IOffenseRecordInstance
  extends IOffenseRecord,
    IAppwriteDocument {}

export const isIOffenseRecordInstance = (
  record: IOffenseRecord | IOffenseRecordInstance,
): record is IOffenseRecordInstance =>
  Object.prototype.hasOwnProperty.call(record, '$id');

export interface IMpuRecord {
  date: number | null;
  organisation: string;
  result: string;
  recommendation: string;
  feb: boolean;
  mbb: boolean;
  clientId: string;
}

export interface IMpuRecordInstance extends IMpuRecord, IAppwriteDocument {}

export const isIMpuRecordInstance = (
  record: IMpuRecord | IMpuRecordInstance,
): record is IMpuRecordInstance =>
  Object.prototype.hasOwnProperty.call(record, '$id');

export interface IOtherRecord {
  date: number | null;
  noteKey: string;
  noteText: string;
  noteAuthor: string;
  clientId: string;
}

export interface IOtherRecordInstance extends IOtherRecord, IAppwriteDocument {}

export const isIOtherRecordInstance = (
  record: IOtherRecord | IOtherRecordInstance,
): record is IOtherRecordInstance =>
  Object.prototype.hasOwnProperty.call(record, '$id');
