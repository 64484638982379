import { useAuthStore } from '@/stores/auth';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'overview',
    component: () => import('@/views/Overview.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Users/Login.vue'),
  },

  // client views
  {
    path: '/klient/:id',
    name: 'client-edit',
    component: () => import('@/views/Client/DetailView.vue'),
  },

  {
    path: '/klient/erstellen',
    name: 'client-create',
    component: () => import('@/views/Client/Create.vue'),
  },

  // client record views
  {
    path: '/klient/akte/erstellen',
    name: 'client-record-create',
    component: () => import('@/views/ClientRecord/Create.vue'),
  },

  {
    path: '/klient/:id/akten',
    name: 'client-record-list',
    component: () => import('@/views/ClientRecord/List.vue'),
  },

  {
    path: '/klient/:clientId/akte/:id',
    name: 'client-record-edit',
    component: () => import('@/views/ClientRecord/Edit.vue'),
  },

  // users

  {
    path: '/benutzer',
    name: 'user-list',
    component: () => import('@/views/Users/List.vue'),
    beforeEnter: () => useAuthStore().isAdmin,
  },

  // catch all
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
