import { Query } from 'appwrite';
import { defineStore } from 'pinia';
import { COLLECTION_IDS } from 'mpu-shared-lib';
import {
  IMpuRecord,
  IMpuRecordInstance,
  isIMpuRecordInstance,
} from '@/types/historyRecords';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { sendErrorToSentry } from '@/sentry';

export const getNewMpuHistoryRecord = (): IMpuRecord => ({
  date: null,
  feb: false,
  mbb: false,
  organisation: '',
  recommendation: '',
  result: '',
  clientId: '',
});

interface StoreState extends Omit<BaseStoreState<IMpuRecordInstance>, 'data'> {
  data: Record<string, IMpuRecordInstance[]>;
}

const { databases, omitDocumentKeys } = useAppwrite();

export const useMpuHistoryStore = defineStore('MpuHistoryStore', {
  state: (): StoreState => ({
    data: {},
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    recordExists(record: IMpuRecordInstance) {
      return this.data[record.clientId].some(
        (existingRecord) => existingRecord.$id === record.$id,
      );
    },

    async create(record: IMpuRecord) {
      const result = await databases.createDocument(
        'MpuDatabase',
        COLLECTION_IDS.MPU_HISTORY_COLLECTION,
        'unique()',
        record,
      );

      if (this.data[record.clientId]) {
        this.data[record.clientId].push({ ...record, ...result });
      } else {
        this.data[record.clientId] = [
          {
            ...record,
            ...result,
          },
        ];
      }

      return result.$id;
    },

    async loadAll() {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.MPU_HISTORY_COLLECTION,
      );
      // clear data so we do not duplicate data
      this.data = {};

      (result.documents as unknown as IMpuRecordInstance[]).forEach(
        (record) => {
          if (this.data[record.clientId]) {
            this.data[record.clientId].push(record);
          } else {
            this.data[record.clientId] = [record];
          }
        },
      );
    },

    async loadSingle(id: string) {
      this.selected = (await databases.getDocument(
        'MpuDatabase',
        COLLECTION_IDS.MPU_HISTORY_COLLECTION,
        id,
      )) as unknown as IMpuRecordInstance;
    },

    async update(id: string, record: IMpuRecordInstance) {
      await databases.updateDocument(
        'MpuDatabase',
        COLLECTION_IDS.MPU_HISTORY_COLLECTION,
        id,
        omitDocumentKeys(record),
      );
      this.loadByClient(record.clientId);
    },

    async delete(record: IMpuRecord | IMpuRecordInstance) {
      try {
        if (isIMpuRecordInstance(record)) {
          const documentId = record.$id;
          const { clientId } = record;

          await databases.deleteDocument(
            'MpuDatabase',
            COLLECTION_IDS.MPU_HISTORY_COLLECTION,
            documentId,
          );

          const index = this.data[clientId].findIndex(
            (item) => item.$id === documentId,
          );

          this.data[clientId].splice(index, 1);
        }
        throw new Error('mpu record could not be delete with no ID');
      } catch (error) {
        sendErrorToSentry(error, 'MpuHistory delete');
      }
    },

    async loadByClient(clientId: string) {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.MPU_HISTORY_COLLECTION,
        [Query.equal('clientId', clientId)],
      );

      // override maybe existing data as we dont want duplicated data
      this.data[clientId] = result.documents as unknown as IMpuRecordInstance[];
    },
  },

  getters: {
    getByClientId: (state) => {
      return (id: string) => state.data[id];
    },
  },
});
