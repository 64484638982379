import { useAppwrite } from '@/utils/appwrite';
import { Models } from 'appwrite';
import { defineStore } from 'pinia';
import router from '@/routes';
import { useAddressStore } from './address';
import { useClientRecordStore } from './clientRecord';
import { useClientStore } from './clients';
import { useLicenseAuthorityStore } from './licenseAuthority';
import { useLicenseHistoryStore } from './licenseHistory';
import { useMpuHistoryStore } from './mpuHistory';
import { useOffenseHistoryStore } from './offenseHistory';
import { useUsersStore } from './users';

interface State {
  session: Models.Session | null;
  user: Models.Account<Models.Preferences> | null;
  isAdmin: boolean;
}

const { account, teams } = useAppwrite();

export const useAuthStore = defineStore('AuthStore', {
  state: (): State => ({
    session: null,
    user: null,
    isAdmin: false,
  }),

  actions: {
    async login(mail: string, password: string) {
      const session = await account.createEmailSession(mail, password);

      this.session = session;

      window.localStorage.setItem('session_key', session.$id);
      await this.getUserData();
      this.pullDataAfterAuth();
    },

    async logout() {
      if (this.session) {
        await account.deleteSession(this.session.$id);
        router.push('/login');
        this.session = null;
        this.user = null;
        this.isAdmin = false;
      }
    },

    async getActiveSession() {
      const sessionId = window.localStorage.getItem('session_key');
      if (sessionId) {
        this.session = await account.getSession(sessionId);
      }

      await this.getUserData();
      this.pullDataAfterAuth();
    },

    async getUserData() {
      this.user = await account.get();
      await this.getIsAdmin();
    },

    async getIsAdmin() {
      try {
        const result = await teams.get('admin');
        if (result.$id) {
          this.isAdmin = true;
        }
      } catch (e) {
        this.isAdmin = false;
      }
    },

    pullDataAfterAuth() {
      useClientStore().loadAll();
      useAddressStore().loadAll();
      useLicenseAuthorityStore().loadAll();
      useClientRecordStore().loadAll();
      useLicenseHistoryStore().loadAll();
      useMpuHistoryStore().loadAll();
      useOffenseHistoryStore().loadAll();

      if (this.isAdmin) {
        useUsersStore().loadAll();
      }
    },
  },

  getters: {
    userName(state) {
      if (state.user !== null) {
        return state.user.name;
      }

      return '';
    },

    id(state) {
      if (state.user !== null) {
        return state.user.$id;
      }

      return '';
    },
  },
});
