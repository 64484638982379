import { defineStore } from 'pinia';
import { FUNCTION_IDS } from 'mpu-shared-lib';

import { useAppwrite } from '@/utils/appwrite';
import { BaseStoreState } from './types';
import { ICreateUser } from '@/types/users';
import { SelectOption } from 'naive-ui';

export interface User {
  $id: string;
  name: string;
  email: string;
}

interface NewUserResponse {
  user: User;
}

interface ErrorResponse {
  status: 'error';
  message: string;
}

const isNewUserResponse = (
  val: NewUserResponse | ErrorResponse,
): val is NewUserResponse => {
  return Object.hasOwn(val, 'user');
};

export const createEmptyUser = () => ({
  email: '',
  name: '',
  password: '',
  passwordCheck: '',
});

const { executeFunction } = useAppwrite();

export const useUsersStore = defineStore('usersStore', {
  state: (): BaseStoreState<User> => ({
    data: [],
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    async loadAll() {
      this.isLoading = true;
      const result = await executeFunction<{ count: number; users: User[] }>(
        FUNCTION_IDS.GET_USERS,
      );

      this.data = result.users;
      this.isLoading = false;
    },

    async create(user: ICreateUser) {
      const result = await executeFunction<NewUserResponse | ErrorResponse>(
        FUNCTION_IDS.CREATE_USER,
        {
          email: user.email,
          name: user.name,
          password: user.password,
        },
      );

      if (isNewUserResponse(result)) {
        this.data.push(result.user);
      } else {
        this.error = result.message;
        throw new Error(result.message);
      }
    },

    async updatePassword(id: string, password: string) {
      await executeFunction(FUNCTION_IDS.UPDATE_USER_PW, {
        id,
        password,
      });
    },

    async deleteUser(id: string) {
      await executeFunction(FUNCTION_IDS.DELETE_USER, { id });

      this.data = this.data.filter((user) => user.$id !== id);
    },
  },
  getters: {
    usersSelect(state) {
      return state.data.map(
        (user): SelectOption => ({
          label: user.name,
          value: user.$id,
        }),
      );
    },
  },
});
