import { SelectOption } from 'naive-ui';
import { defineStore } from 'pinia';
import { COLLECTION_IDS } from 'mpu-shared-lib';
import {
  ILicenseAuthority,
  ILicenseAuthorityInstance,
} from '../types/LicenseAuthority';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { useAddressStore } from './address';
import { IAddress } from '@/types/address';
import { sendErrorToSentry } from '@/sentry';
import { orderBy } from 'lodash-es';
import { Query } from 'appwrite';

export const newLicenseAuthority = (): ILicenseAuthority => ({
  addressId: '',
  authority: '',
  faxNumber: '',
  phoneNumber: '',
});

const { databases, omitDocumentKeys } = useAppwrite();

export const useLicenseAuthorityStore = defineStore('LicenseAuthorityStore', {
  state: (): BaseStoreState<ILicenseAuthorityInstance> => ({
    data: [],
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    async create(licenseAuthority: ILicenseAuthority, address: IAddress) {
      const addressId = await useAddressStore().create(address);

      const result = await databases.createDocument(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_AUTHORITY_COLLECTION,
        'unique()',
        {
          ...licenseAuthority,
          addressId,
        },
      );

      this.data.push({
        ...licenseAuthority,
        addressId,
        ...result,
      });
    },

    async loadAll() {
      try {
        const result = await databases.listDocuments(
          'MpuDatabase',
          COLLECTION_IDS.LICENSE_AUTHORITY_COLLECTION,
          [Query.limit(100)],
        );
        this.data = result.documents as unknown as ILicenseAuthorityInstance[];
      } catch (error) {
        sendErrorToSentry(error, 'licenseAuthority loadAll');
      }
    },

    async loadSingle(id: string) {
      try {
        this.selected = (await databases.getDocument(
          'MpuDatabase',
          COLLECTION_IDS.LICENSE_AUTHORITY_COLLECTION,
          id,
        )) as unknown as ILicenseAuthorityInstance;
      } catch (error) {
        sendErrorToSentry(error, 'licenseAuthority loadSingle');
      }
    },

    async update(id: string, licenseAuthority: ILicenseAuthority) {
      try {
        await databases.updateDocument(
          'MpuDatabase',
          COLLECTION_IDS.LICENSE_AUTHORITY_COLLECTION,
          id,
          omitDocumentKeys(licenseAuthority),
        );

        const index = this.data.findIndex((authoirty) => authoirty.$id === id);
        this.data[index] = {
          ...this.data[index],
          ...licenseAuthority,
        };
      } catch (error) {
        sendErrorToSentry(error, 'licenseAuthority update');
      }
    },
  },

  getters: {
    getById: (state) => (id: string) =>
      state.data.find((licenseAuthority) => licenseAuthority.$id === id),

    licenseAuthoritySelect: (state): SelectOption[] => {
      const result: SelectOption[] = [];

      state.data.forEach((licenseAuthority) => {
        result.push({
          label: licenseAuthority.authority,
          value: licenseAuthority.$id,
        });
      });

      return orderBy(result, [(i) => i.label?.toString().toLocaleLowerCase()]);
    },
  },
});
