import { Query } from 'appwrite';
import { defineStore } from 'pinia';
import { COLLECTION_IDS } from 'mpu-shared-lib';
import {
  ILicenseRecord,
  ILicenseRecordInstance,
  isILicenseRecordInstance,
} from '@/types/historyRecords';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { sendErrorToSentry } from '@/sentry';

export const getNewLicenseHistoryRecord = (): ILicenseRecord => ({
  date: null,
  label: '',
  licenseClasses: '',
  clientId: '',
});

interface StoreState
  extends Omit<BaseStoreState<ILicenseRecordInstance>, 'data'> {
  data: Record<string, ILicenseRecordInstance[]>;
}

const { databases, omitDocumentKeys } = useAppwrite();

export const useLicenseHistoryStore = defineStore('LicenseHistoryStore', {
  state: (): StoreState => ({
    data: {},
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    recordExists(record: ILicenseRecordInstance) {
      return this.data[record.clientId].some(
        (existingRecord) => existingRecord.$id === record.$id,
      );
    },

    async create(licenseRecord: ILicenseRecord) {
      const result = await databases.createDocument(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
        'unique()',
        licenseRecord,
      );

      if (this.data[licenseRecord.clientId]) {
        this.data[licenseRecord.clientId].push({ ...licenseRecord, ...result });
      } else {
        this.data[licenseRecord.clientId] = [
          {
            ...licenseRecord,
            ...result,
          },
        ];
      }

      return result.$id;
    },

    async loadAll() {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
      );
      // clear data so we do not duplicate data
      this.data = {};

      (result.documents as unknown as ILicenseRecordInstance[]).forEach(
        (record) => {
          if (this.data[record.clientId]) {
            this.data[record.clientId].push(record);
          } else {
            this.data[record.clientId] = [record];
          }
        },
      );
    },

    async loadSingle(id: string) {
      this.selected = (await databases.getDocument(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
        id,
      )) as unknown as ILicenseRecordInstance;
    },

    async update(id: string, licenseRecord: ILicenseRecordInstance) {
      await databases.updateDocument(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
        id,
        omitDocumentKeys(licenseRecord),
      );
    },

    async delete(record: ILicenseRecord | ILicenseRecordInstance) {
      try {
        if (isILicenseRecordInstance(record)) {
          const documentId = record.$id;
          const { clientId } = record;

          await databases.deleteDocument(
            'MpuDatabase',
            COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
            documentId,
          );

          const index = this.data[clientId].findIndex(
            (item) => item.$id === documentId,
          );

          this.data[clientId].splice(index, 1);
        }
        throw new Error('license record could not be delete with no ID');
      } catch (error) {
        sendErrorToSentry(error, 'LicenseHistory delete');
      }
    },

    async loadByClient(clientId: string) {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.LICENSE_HISTORY_COLLECTION,
        [Query.equal('clientId', clientId)],
      );

      // override maybe existing data as we dont want duplicated data
      this.data[clientId] =
        result.documents as unknown as ILicenseRecordInstance[];
    },
  },

  getters: {
    getByClientId: (state) => {
      return (id: string) => state.data[id];
    },
  },
});
